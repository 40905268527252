import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

export default function useProjectV2(id: string) {
  const queryKey = queryKeys.PROJECT_DETAILS(id)

  const queryFn = async () => {
    const res = await financialBff('query')({
      project: [{ projectId: id }, {
        project_value: true,
        project_name: true,
        installments: true,
        down_payment: true,
        partner_id: true,
        person_type: true,
        gained_points: true,
        has_energy_bill_analysis: true,
        is_onflex_partner: true,
        new_journey: true,
        original_installment_value: true,
        insurance_commission: true,
        selected_installments: {
          grace_period: true,
          installment_value: true,
          installments: true,
          interest_rate: true,
          cet_percentage: true,
          commission_raw: true,
          aggregated_addons: {
            id: true,
            product_slug: true,
            type: true,
            installment_price: true,
            applied: true,
          },
          addons: {
            id: true,
            product_slug: true,
            type: true,
            installment_price: true,
            applied: true,
          },
        },
      }],
    })

    if (!res.project)
      return null

    return { ...res.project }
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 3,
    retryDelay: 1000,
    staleTime: 1000 * 60 * 1, // 1 minute
  })
}

import type { HardwareBodyDraft } from '~/utils/installation/Installation'
import { useMutation } from '@tanstack/vue-query'
import financialBff from '~/services-v2/financial-bff/financial-bff'

interface ShowcaseMutationInput extends HardwareBodyDraft {
  shopping_cart_id: string
}

export default function useShowcaseMutation() {
  const mutationFn = async (data: ShowcaseMutationInput) => {
    const inverters = data.inverters?.map(inverter => ({
      id: Number(inverter.sku),
      quantity: inverter.quantity,
      power: inverter.power,
    })) || []

    const modules = data.modules?.map(module => ({
      id: Number(module.sku),
      quantity: module.quantity,
      power: module.power,
    })) || []

    await financialBff('mutation')({
      hardware_form_showcase: [
        {
          hardwareFormShowcaseInput: {
            inverters,
            modules,
            project_id: data.project_id,
            price: data.price,
            shopping_cart_id: data.shopping_cart_id,
          },
        },
        {
          overload_is_valid: true,
        },
      ],
    })
  }

  return useMutation({
    mutationFn,
    retry: 3,
  })
}

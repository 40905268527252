<script setup lang="ts">
import type { HardwareBodySave, HardwareCombos, HardwareCombosModel, HardwareCombosSelect, OptionsForm } from '~/utils/installation/Installation'
import { useToast } from '@solfacil/girassol'
import { toTypedSchema } from '@vee-validate/zod'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'
import AddIcon from '~icons/material-symbols/add'
import DeleteIcon from '~icons/material-symbols/delete-outline'
import InstallationService from '~/services/installation/Installation'
import useFinancingStatus from '~/store-v2/financings/useFinancingStatus'
import useOpenableSteps from '~/store-v2/financings/useOpenableSteps'
import useHardwareValidation from '~/store-v2/hardware/useHardwareValidation'
import { getHardwareListLabels } from './utils'

const props = defineProps<{
  hardwareInformation: HardwareCombosModel
  refetchForm: () => Promise<void>
}>()

const route = useRoute()
const projectId = computed(() => String(route.params.id))

const {
  refetch: refetchDataStepsStatus,
} = useOpenableSteps(projectId)

const { track } = useMixpanel()

const projectHardwareCombos = ref({} as HardwareCombos)

const installationService = new InstallationService(useApi('installation'))

const projectShoppingCartId: any = ref('')

const { t } = useI18n()
const router = useRouter()
const {
  createErrorToast,
  createSuccessToast,
} = useToast()
const { data: financing, isLoading } = useFinancingStatus(projectId)

const { mutateAsync: hardwareValidation, isPending: isValidationPending } = useHardwareValidation()

const disableStepsAccordingStatus = computed(() => {
  const hardwareIsPending = financing.value?.section_statuses?.formalization?.hardware === 'pending'
  const isFinancingCanceledOrExpired = ['canceled', 'expired'].includes(financing.value?.status ?? '')
  return (isFinancingCanceledOrExpired || !hardwareIsPending) && isLoading
})

const hasStoreOrder = props.hardwareInformation.has_store_order

const inverterSelectionFieldCount = ref()
const loadingProjectData = ref(false)
const showButtonNext = ref(false)
const id_project = String(router.currentRoute.value.params.id)
const loadingBackgroundInfo = computed(() => isValidationPending.value)

function setQuantityOfInvertersFields() {
  const inverters = props.hardwareInformation?.inverters
  inverterSelectionFieldCount.value = inverters && inverters.length > 0 ? inverters.length : 1
}

const radiosInverterType = [
  {
    name: 'inverterType',
    value: 'string',
    label: 'Normal (String)',
  },
  {
    name: 'inverterType',
    value: 'microinverter',
    label: 'Microinversor',
  },
]

const optionsSelect = ref({} as HardwareCombosSelect)

async function getCombosHardware() {
  try {
    const { data } = await installationService.get_combos_hardware(id_project)
    if (data) {
      projectHardwareCombos.value = data

      for (const key in data) {
        if (data[key]) {
          optionsSelect.value[key] = data[key].map((item: OptionsForm) => ({
            name: getHardwareListLabels(item, key),
            value: Number(item.id),
          }))
        }
      }
    }
  }
  catch (error: any) {
    return createErrorToast(error?.data?.detail ? error.data.detail : 'Não foi possível carregar as informações das seleções do formulário. Por favor, tente novamente.')
  }
}
const inverterTypeSelect = ref('')

const isMicroinversor = ref((props.hardwareInformation?.microinverters?.length ?? 0) > 0)

const validationSchema = computed(() => toTypedSchema(
  zod.object({
    distributor: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    installationPhase: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    inverterType: zod.enum(['string', 'microinverter']),
    moduleQuantity: zod.union([zod.string().refine(value => /^[1-9]\d*$/.test(value), t('form.required')), zod.number().min(1, t('form.required'))]),
    microinversorQuantity: inverterTypeSelect.value === 'microinverter'
      ? zod.union([zod.string()
        .refine(value => /^[1-9]\d*$/.test(value), t('form.required')), zod.number().min(1, t('form.required'))])
      : zod.any().optional(),
    inverters: (inverterTypeSelect.value === 'microinverter')
      ? zod.any().optional()
      : zod.array(zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }, { required_error: t('form.required') })),
    microinversors: inverterTypeSelect.value === 'microinverter'
      ? zod.array(zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }))
      : zod.any().optional(),
    modules: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
  }),
))

const { values, validate, setFieldValue, errors, setErrors } = useForm({
  validationSchema,
  initialValues: {
    distributor: {
      name: props?.hardwareInformation?.distributor?.slug || props?.hardwareInformation?.distributor?.name || 'Selecione',
      value: props?.hardwareInformation?.distributor?.id || 0,
    },
    installationPhase: {
      name: props?.hardwareInformation?.phase?.name || 'Selecione',
      value: props?.hardwareInformation?.phase?.id || 0,
    },
    inverterType: props?.hardwareInformation?.inverter_type || 'string',
    inverters: props?.hardwareInformation?.inverters !== null && props?.hardwareInformation?.inverters?.length > 0
      ? props.hardwareInformation.inverters.map((item) => { return { name: getHardwareListLabels(item as unknown as OptionsForm, 'inverters'), value: item.id } })
      : props.hardwareInformation?.inverters === null ? null : [],
    moduleQuantity: props.hardwareInformation.modules !== null && props.hardwareInformation?.modules?.length > 0 ? props.hardwareInformation.modules[0].quantity : '',
    microinversors: [{
      name: isMicroinversor.value ? getHardwareListLabels(props.hardwareInformation?.microinverters?.[0] as unknown as OptionsForm, 'microinverters') : 'Selecione',
      value: isMicroinversor.value ? props.hardwareInformation?.microinverters?.[0]?.id : 0,
    }],
    modules: props?.hardwareInformation?.modules !== null && props?.hardwareInformation?.modules?.length > 0
      ? props.hardwareInformation?.modules?.map((item) => { return { name: getHardwareListLabels(item as unknown as OptionsForm, 'modules'), value: item.id } })?.reduce((acc, cur) => Object.assign(acc, cur))
      : { name: 'Selecione', value: 0 },
    microinversorQuantity: props?.hardwareInformation?.microinverters && (props?.hardwareInformation?.microinverters?.length ?? 0) > 0
      ? Number(props?.hardwareInformation?.microinverters?.map((item) => { return item.quantity })?.reduce((acc, cur) => Object.assign(acc, cur)))
      : '',
  },
})

watch(values, (newValue) => {
  inverterTypeSelect.value = newValue.inverterType ?? ''
})

function showErrorStringInverter(isUnderExpected: boolean) {
  if (isUnderExpected) {
    values.inverters?.forEach((_, index) => {
      setErrors({
        ...errors.value,
        [`inverters[${index}]`]: t('project_data.errors.string_overload_min'),
      })
    })

    return
  }

  values.inverters?.forEach((_, index) => {
    setErrors({
      ...errors.value,
      [`inverters[${index}]`]: t('project_data.errors.string_overload_max'),
    })
  })
}

function showErrorMicroinverter(isUnderExpected: boolean) {
  if (isUnderExpected) {
    values.microinversors?.forEach((_, index) => {
      setErrors({
        ...errors.value,
        microinversorQuantity: ' ',
        [`microinversors[${index}]`]: t('project_data.errors.microinverter_overload_min'),
      })
    })

    return
  }

  values.microinversors?.forEach((_, index) => {
    setErrors({
      ...errors.value,
      microinversorQuantity: ' ',
      [`microinversors[${index}]`]: t('project_data.errors.microinverter_overload_max'),
    })
  })
}

function showPricePerWattError() {
  setErrors({
    ...errors.value,
    moduleQuantity: ' ',
    modules: t('project_data.errors.modules_price_per_watt'),
  })
}

async function validateHardwareForm(form: HardwareBodySave) {
  const validation = await hardwareValidation(form)

  if (!validation) {
    return { isValid: false }
  }

  if (validation.is_valid) {
    setErrors({})
  }
  else {
    if (validation.hardware?.modules?.price_per_watt?.is_valid === false) {
      showPricePerWattError()
    }

    if (validation.hardware?.inverters?.overload?.is_valid === false) {
      const { is_under_expected: _isUnderExpected } = validation.hardware.inverters.overload
      const isUnderExpected = Boolean(_isUnderExpected)

      if (values.inverterType === 'string') {
        showErrorStringInverter(isUnderExpected)
      }
      else {
        showErrorMicroinverter(isUnderExpected)
      }
    }
  }

  return { isValid: validation.is_valid }
}

function addInverter() {
  inverterSelectionFieldCount.value = inverterSelectionFieldCount.value + 1
  track('formalizing_equipments_button_add-inverter', { trigger: 'Clique no botão de adicionar inversor' })
}

function removeInverter(index: number) {
  const valueToRemove = values.inverters[index]
  const inverterToRemove = values.inverters.filter(item => item !== valueToRemove)
  setFieldValue('inverters', inverterToRemove)
  inverterSelectionFieldCount.value = values?.inverters?.length
}

function resetField() {
  inverterSelectionFieldCount.value = 1
  if (props.hardwareInformation?.distributor !== null && props.hardwareInformation?.distributor?.id === 0)
    setFieldValue('distributor', { name: 'Selecione', value: 0 })

  if (props.hardwareInformation?.phase !== null && props.hardwareInformation.phase.id === 0)
    setFieldValue('installationPhase', { name: 'Selecione', value: 0 })

  setFieldValue('moduleQuantity', '')
  setFieldValue('modules', { name: 'Selecione', value: 0 })

  if (values.inverterType === 'microinverter') {
    isMicroinversor.value = true
    setFieldValue('microinversors', [{ name: 'Selecione', value: 0 }])
  }

  else {
    isMicroinversor.value = false
    setFieldValue('inverters', null)
  }
}

watch(projectShoppingCartId, (newValue) => {
  if (newValue)
    projectShoppingCartId.value = newValue
})

function getHardwareFormPayload() {
  // eslint-disable-next-line style/max-statements-per-line
  const invertersPayload = isMicroinversor.value ? values.microinversors?.map((item: { name: string, value: number }) => { return { name: item.name, id: item.value, quantity: Number.parseInt(values.microinversorQuantity) } }) : values.inverters?.map((item: { name: string, value: number }) => { return { name: item.name, id: item.value, quantity: 1 } })

  const hardwareDataForm: HardwareBodySave = {
    is_combo: props.hardwareInformation.is_combo,
    project_id: id_project,
    distributor_id: values?.distributor?.value !== undefined ? values?.distributor?.value : 0,
    phase_id: values?.installationPhase?.value !== undefined ? values?.installationPhase?.value : 0,
    inverters: invertersPayload,
    modules: [{ id: values.modules?.value ?? 0, quantity: Number(values.moduleQuantity) }],
    inverter_type: values.inverterType,
  }

  return hardwareDataForm
}

async function saveProject() {
  const { valid } = await validate()

  if (valid) {
    try {
      loadingProjectData.value = true

      const hardwareDataForm = getHardwareFormPayload()

      await installationService.post_save_hardware(hardwareDataForm)
      await props.refetchForm()
      refetchDataStepsStatus()

      createSuccessToast('Equipamentos salvos com sucesso')
      track('formalizing_equipments_button_save', { trigger: 'Clique no botão de salvar na seção de equipamentos', ...hardwareDataForm })

      showButtonNext.value = true
    }
    catch (error: any) {
      loadingProjectData.value = false
      return createErrorToast(error?.data?.detail ? error.data.detail : 'Erro ao salvar os equipamentos. Tente novamente mais tarde.')
    }
    finally {
      loadingProjectData.value = false
    }
  }
  else {
    createErrorToast(t('form.alert_required_fields'))
  }
}

async function submitTechnicalData() {
  const { valid } = await validate()

  if (!valid) {
    return
  }

  const hardwareDataForm = getHardwareFormPayload()

  const { isValid } = await validateHardwareForm(hardwareDataForm)

  if (isValid)
    await saveProject()
}

onMounted(async () => {
  setQuantityOfInvertersFields()
  await getCombosHardware()
})

function disabledFields(value: boolean) {
  return disableStepsAccordingStatus.value || value
}
</script>

<template>
  <div class="form-project-data">
    <form id="hardware-form">
      <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6 mt-6 mb-6">
        <SolSelect
          id="energy-concessionaire-field"
          name="distributor"
          :label="`${t('form.energy_concessionaire')}`"
          :use-field="useField"
          :options="optionsSelect.distributor"
          :searchable="true"
          :disabled="disabledFields(Boolean(props.hardwareInformation.distributor)) || hasStoreOrder"
        />
        <SolSelect
          id="installation-phase-field"
          name="installationPhase"
          :label="`${t('form.installation_phase')}`"
          :use-field="useField"
          :options="optionsSelect.phase"
          :disabled="disabledFields(Boolean(props.hardwareInformation.phase)) || hasStoreOrder"
        />
      </div>

      <!-- Hardware Form -->
      <label
        :class="{ 'pointer-events-none opacity-60': disableStepsAccordingStatus }"
        for="inverter-type"
        class="fonts-body-medium-bold text-neutral-low-dark"
      >
        {{ t('project_data.form.inverter_type') }}
      </label>
      <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6">
        <SolRadioGroup
          id="inverter-type"
          class="mb-2"
          title=""
          name="inverterType"
          :use-field="useField"
          direction="row"
          :radios="radiosInverterType"
          :disabled="disableStepsAccordingStatus || hasStoreOrder"
          @change="resetField()"
        />
      </div>
      <!-- Exibe os campos de acordo com o microinverter -->
      <template v-if="values.inverterType === 'microinverter'">
        <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6 mt-6">
          <SolInputText
            id="microinversor-quantity-field"
            name="microinversorQuantity"
            type="number"
            :use-field="useField"
            :label="`${t('form.microinversor_quantity')}`"
            :disabled="disableStepsAccordingStatus || hasStoreOrder"
          />
          <div class="md:system:col-span-3">
            <SolSelect
              v-for="(_, index) in values.microinversors"
              id="microinversor-field"
              :key="index"
              class="w-full"
              :name="`microinversors[${index}]`"
              :label="`${t('form.microinversors')}`"
              :use-field="useField"
              :options="optionsSelect.microinverters"
              :searchable="true"
              :disabled="disableStepsAccordingStatus || hasStoreOrder"
            />
          </div>
        </div>
      </template>
      <!-- Exibe os campos de acordo com o Normal (String) -->
      <template v-else>
        <div class="grid grid-cols-1 gap-6 mt-6">
          <div
            v-for="(_, index) in inverterSelectionFieldCount"
            :key="index"
            class="flex items-end gap-2"
          >
            <SolSelect
              :id="`inverter-field-${index}`"
              class="w-full"
              :name="`inverters[${index}]`"
              :label="`${t('form.inverter')}`"
              :use-field="useField"
              :options="optionsSelect.inverters"
              :searchable="true"
              :disabled="disableStepsAccordingStatus || hasStoreOrder"
            />
            <template v-if="index > 0">
              <SolButton
                id="removeInverter"
                size="large"
                variant="tertiary"
                class="!h-[48px]"
                :disabled="disableStepsAccordingStatus || hasStoreOrder"
                @click="removeInverter(index)"
              >
                <template #icon:left>
                  <DeleteIcon />
                </template>
              </SolButton>
            </template>
          </div>

          <SolButton
            id="addInverter"
            size="large"
            variant="tertiary"
            :disabled="values?.inverters?.some((item) => item === undefined) || disableStepsAccordingStatus || hasStoreOrder"
            @click="addInverter()"
          >
            <template #icon:left>
              <AddIcon />
            </template>
            {{ t('form.button.add_inverter') }}
          </SolButton>
        </div>
      </template>

      <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6 mt-6">
        <SolInputText
          id="module-quantity-field"
          name="moduleQuantity"
          type="number"
          :use-field="useField"
          :label="`${t('form.module_quantity')}`"
          :disabled="disableStepsAccordingStatus || hasStoreOrder"
        />
        <div class="md:system:col-span-3">
          <SolSelect
            id="module-field"
            class="w-full"
            name="modules"
            :label="`${t('form.modules')}`"
            :use-field="useField"
            :options="optionsSelect.modules"
            :searchable="true"
            :disabled="disableStepsAccordingStatus || hasStoreOrder"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 gap-6 mt-6">
        <SolDivider thickness="x-small" orientation="horizontal" />
      </div>
      <div class="grid grid-cols-1 md:system:grid-cols-4 gap-6 mt-6">
        <div class="md:system:col-start-4 flex justify-end">
          <SolButton
            id="add-equipment-button"
            size="large"
            class="w-full md:system:w-auto"
            :loading="loadingProjectData || loadingBackgroundInfo"
            :disabled="disableStepsAccordingStatus || Object.keys(errors).length > 0 || hasStoreOrder"
            @click="submitTechnicalData"
          >
            {{ t('app.save') }}
          </SolButton>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
#hardware-form {
  :deep(.sol-alert-core.-informative) {
    @apply block mb-2xs;

    @screen md:system {
      @apply flex;
    }

    .sol-button-core.-secondary {
      @apply ml-sm mt-micro min-w-max;

      @screen md:system {
        @apply ml-0 mt-0;
      }
    }
  }
}

.form-project-data{
  @apply mt-sm mb-0;
}
</style>

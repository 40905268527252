import type { SimulatorService } from './services/simulator-v2/simulator.types'
import type { UseBankStatementParams } from './store-v2/bank-statement/useBankStatement'
import type { UseCustomerParams } from './store-v2/customer/useCustomer'
import type { UseFinancingsParams } from './store-v2/financings/useFinancings'
import type { UsePartnersParams } from './store-v2/partners/usePartners'
import { QueryClient } from '@tanstack/vue-query'
import { filterAndFormatParams } from './utils/objects'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: 3,
      staleTime: 1000 * 60 * 3, // 3 minutes
      gcTime: 1000 * 60 * 5, // 5 minutes
    },
  },
})

export const queryKeys = {
  AUTH: {
    LOGIN: ['AUTH', 'LOGIN'],
    LOGOUT: ['AUTH', 'LOGOUT'],
    STATUS: ['AUTH', 'STATUS'],
  },
  SF_PLUS: {
    STATUS: ['SF_PLUS', 'STATUS'],
  },
  FINANCINGS: {
    DETAILS: (id: Ref<string>) => ['FINANCINGS', 'DETAILS', id.value],
    LIST: (params: UseFinancingsParams) => ['FINANCINGS', 'LIST', ...filterAndFormatParams(params)],
    STATUS: (id: Ref<string>) => ['FINANCINGS', 'STATUS', id.value],
    STEPS_STATUS: ['STEPS_STATUS'],
  },
  PARTNERS: {
    LIST: (params: UsePartnersParams) => ['PARTNERS', 'LIST', ...filterAndFormatParams(params)],
  },
  BANK_STATEMENT: {
    LIST: (params: UseBankStatementParams) => ['BANK_STATEMENT', 'LIST', ...filterAndFormatParams(params)],
  },
  CUSTOMER: {
    UPDATE: ['CUSTOMER', 'CONTACT', 'UPDATE'],
    DATA: (params: UseCustomerParams) => ['CUSTOMER', 'DATA', ...filterAndFormatParams(params)],
  },
  REGISTRATION: {
    COMPANY: (id: string) => ['REGISTRATION', 'COMPANY', id],
    CUSTOMER: (id: string) => ['REGISTRATION', 'CUSTOMER', id],
  },
  // projects
  PROJECTS: (params: SimulatorService.ProjectsRequest) => ['PROJECTS', ...filterAndFormatParams(params)],
  PROJECT: (id: string) => ['PROJECT', id],
  PROJECT_DETAILS: (id: string) => ['PROJECT_DETAILS', id],
  OPPORTUNITIES: (params: SimulatorService.GetOpportunitiesRequest) => ['OPPORTUNITIES', ...filterAndFormatParams(params)],

  ANALYSIS_BILL: ['ANALYSIS_BILL'],
  FINANCING_TYPES: ['FINANCING_TYPES'],

  BNPL_SUMMARY: (id: string) => ['BNPL_SUMMARY', id],
  BNPL_INSTALLMENTS_MUTATION: (projectID: string) => ['BNPL_INSTALLMENTS', projectID],
  BNPL_INSTALLMENT: (id: string) => ['BNPL_INSTALLMENT', id],

  LEGACY_FINANCING: (projectId: string) => ['LEGACY_FINANCING', projectId],

  INSTALLATIONS: {
    DETAILS: (projectId: Ref<string>) => ['INSTALLATIONS', 'DETAILS', projectId.value],
  },

  HARDWARE: {
    FORM: (projectId: string) => ['HARDWARE', 'FORM', projectId],
    DRAFT: ['HARDWARE', 'DRAFT'],
    VALIDATION: ['HARDWARE', 'VALIDATION'],
    MIRROR_STATUS: (projectId: string) => ['HARDWARE', 'MIRROR_STATUS', projectId],
    MIRROR_UPLOAD: ['HARDWARE', 'MIRROR_UPLOAD'],
  },

  SIMULATION: {
    GET_SHOPPING_CART_ID: (projectId: string) => ['SIMULATION', 'GET_SHOPPING_CART_ID', projectId],
    PUT_SHOPPING_CART_ID: ['SIMULATION', 'PUT_SHOPPING_CART_ID'],
  },
}

export default queryClient

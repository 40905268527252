import type { CustomerRegisterAPI } from './customer-register-api.types'
import http from './customer-register-api.http'

export default {
  /**
   * Get company registration form
   * @param projectId
   */
  async getCompanyRegistrationForm(projectId: string) {
    const response = await http.get<CustomerRegisterAPI.GetCompanyRegistrationFormResponse>(`/registration/form/${projectId}/pj`)
    return response.data
  },

  /**
   * Get customer registration form
   * @param projectId
   */
  async getCustomerRegistrationForm(projectId: string) {
    const response = await http.get<CustomerRegisterAPI.GetCustomerRegistrationFormResponse>(`/registration/form/${projectId}/pf`)
    return response.data
  },
}

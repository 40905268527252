/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Order: "enum" as const,
	Upload: `scalar.Upload` as const,
	Query:{
		address_combos:{

		},
		address_cities:{

		},
		address_partial:{

		},
		address_installation:{

		},
		customer_form:{
			customer_type:"PersonType"
		},
		document_form_pf:{

		},
		document_form_pj:{

		},
		financing:{

		},
		financings:{
			input:"FinancingsInput"
		},
		financing_type:{

		},
		installation:{

		},
		mirrorStatus:{

		},
		legacyFinancing:{

		},
		partners:{
			input:"CoreGetPartnersInput"
		},
		project:{

		},
		project_proposal_changes:{

		},
		project_is_combo:{

		},
		getOpportunities:{
			params:"GetOpportunitiesInput"
		},
		simulation_project_shopping_cart_id_get:{

		}
	},
	PersonType: "enum" as const,
	NullOrString: "enum" as const,
	Person:{

	},
	Mutation:{
		customer:{

		},
		company:{
			majority_partner:"Person",
			partners:"Person"
		},
		validateHardwareForm:{
			hardwareFormInput:"HardwareFormInput"
		},
		hardware_form_showcase:{
			hardwareFormShowcaseInput:"HardwareFormShowcaseInput"
		},
		hardware_form_draft:{
			hardwareFormDraftInput:"HardwareDraft"
		},
		installation_mirror_upload:{
			mirror:"Upload"
		},
		project_update_shopping_cart:{

		},
		project_proposal_update:{
			payload:"ProjectProposalUpdatePayloadInput"
		},
		processEnergyAccountAnalysis:{
			params:"AnalyzeEnergyBillInput"
		}
	},
	FinancingStatus: "enum" as const,
	SectionStatus: "enum" as const,
	FinancingSteps: "enum" as const,
	FinancingsInput:{
		steps:"FinancingSteps",
		status:"FinancingStatus"
	},
	FileStatusType: "enum" as const,
	DocumentStatusType: "enum" as const,
	TypeSection: "enum" as const,
	MirrorStatuses: "enum" as const,
	HardwareFormInverter:{

	},
	HardwareFormModule:{

	},
	HardwareFormComboEquipment:{

	},
	HardwareFormComboMetadata:{
		inverters:"HardwareFormComboEquipment",
		modules:"HardwareFormComboEquipment"
	},
	HardwareFormInput:{
		inverters:"HardwareFormInverter",
		modules:"HardwareFormModule",
		combo_metadata:"HardwareFormComboMetadata"
	},
	HardwareFormShowcaseInput:{
		inverters:"HardwareFormInverter",
		modules:"HardwareFormModule"
	},
	HardwareDraftEquipment:{

	},
	HardwareDraft:{
		inverters:"HardwareDraftEquipment",
		modules:"HardwareDraftEquipment"
	},
	CoreGetPartnersInput:{

	},
	ProjectProposalUpdatePayloadInput:{

	},
	AnalyzeEnergyBillInput:{

	},
	GetOpportunitiesInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	Upload: `scalar.Upload` as const,
	Combos:{
		data:"ComboData"
	},
	Cities:{
		data:"ComboNameId"
	},
	AddressPartial:{
		data:"AddressData"
	},
	AddressInstallation:{
		data:"AddressInstallationData"
	},
	ComboData:{
		nationality:"ComboNameId",
		gender:"ComboNameId",
		occupation:"ComboNameId",
		state:"ComboNameId"
	},
	AddressData:{
		neighborhood:"String",
		street:"String",
		state:"ComboNameId",
		city:"ComboNameId"
	},
	AddressInstallationData:{
		postal_code:"String",
		street:"String",
		street_number:"Int",
		additional_info:"String",
		neighborhood:"String",
		city:"String",
		state:"String",
		rural_area:"Boolean"
	},
	ComboNameId:{
		name:"String",
		combo_id:"String"
	},
	Query:{
		address_combos:"Combos",
		address_cities:"Cities",
		address_partial:"AddressPartial",
		address_installation:"AddressInstallation",
		customer_form:"CutomerFormResponse",
		document_form_pf:"DocumentFormPfResponse",
		document_form_pj:"DocumentFormPjResponse",
		financing:"Financing",
		financings:"FinancingsList",
		financing_type:"FinancingType",
		installation:"Installation",
		mirrorStatus:"MirrorStatus",
		legacyFinancing:"LegacyFinancing",
		partners:"CorePartner",
		project:"Project",
		project_proposal_changes:"ProjectProposal",
		project_is_combo:"IsComboResponse",
		getOpportunities:"GetOpportunitiesResponse",
		simulation_project_shopping_cart_id_get:"String",
		me:"User"
	},
	CustomerUpdateResponse:{
		message:"String",
		status:"Int"
	},
	CompanyUpdateResponse:{
		message:"String",
		status:"Int"
	},
	CustomerState:{
		canceled_at:"NullOrString",
		name:"String",
		updated_at:"String",
		id:"Int",
		created_at:"String"
	},
	CustomerCity:{
		name:"String",
		updated_at:"String",
		created_at:"String",
		id:"Int",
		state_id:"Int",
		canceled_at:"NullOrString",
		state:"CustomerState"
	},
	CustomerAddress:{
		street:"String",
		id:"String",
		additional_info:"String",
		rural_area:"Boolean",
		address_id:"String",
		canceled_at:"NullOrString",
		postal_code:"String",
		street_number:"Int",
		neighborhood:"String",
		city_id:"Int",
		state_id:"Int",
		created_at:"String",
		updated_at:"String",
		state:"CustomerState",
		city:"CustomerCity"
	},
	CustomerPersonForm:{
		full_name:"String",
		birthdate:"String",
		mother_name:"String",
		nationality_id:"Int",
		occupation_id:"Int",
		created_at:"String",
		updated_at:"String",
		id:"String",
		cpf:"String",
		phone:"String",
		email:"String",
		gender_id:"Int",
		person_id:"String",
		canceled_at:"NullOrString",
		gender:"String",
		nationality:"String",
		occupation:"String"
	},
	CompanyLegalRepresentant:{
		person_form_id:"String",
		id:"Int",
		updated_at:"String",
		address_form_id:"String",
		company_registration_form_id:"String",
		created_at:"String",
		canceled_at:"NullOrString",
		person_form:"CustomerPersonForm",
		address_form:"CustomerAddress"
	},
	CompanyForm:{
		id:"String",
		document:"String",
		company_name:"String",
		foundation_date:"String",
		canceled_at:"NullOrString",
		business_email:"String",
		business_phone:"String",
		state_registration:"String",
		created_at:"String",
		updated_at:"String"
	},
	CustomerProvider:{
		id:"Int",
		name:"String",
		created_at:"String",
		updated_at:"String",
		canceled_at:"NullOrString"
	},
	CustomerStatus:{
		updated_at:"String",
		reason:"NullOrString",
		created_at:"String",
		slug:"String",
		id:"Int",
		canceled_at:"NullOrString"
	},
	CustomerFormData:{
		id:"String",
		majority_partner_person_form_id:"String",
		majority_partner_address_form_id:"String",
		installation_address_form_id:"String",
		project_id:"String",
		changes_after_confirmation:"Int",
		canceled_at:"NullOrString",
		company_form_id:"String",
		majority_partner_is_legal_representant:"Boolean",
		electricity_bill_document:"String",
		status:"CustomerStatus",
		created_at:"String",
		updated_at:"String",
		majority_partner_address_form:"CustomerAddress",
		legal_representants:"CompanyLegalRepresentant",
		installation_address_form:"CustomerAddress",
		company_form:"CompanyForm",
		majority_partner_person_form:"CustomerPersonForm",
		remaining_changes_after_confirmation:"Int",
		external_id:"String",
		residential_address_form_id:"String",
		status_id:"Int",
		installation_document:"String",
		person_form_id:"String",
		provider_id:"Int",
		plant_address_form_id:"String",
		person_form:"CustomerPersonForm",
		residential_address_form:"CustomerAddress",
		provider:"CustomerProvider",
		plant_address_form:"CustomerAddress"
	},
	CutomerFormResponse:{
		data:"CustomerFormData"
	},
	Mutation:{
		customer:"CustomerUpdateResponse",
		company:"CompanyUpdateResponse",
		validateHardwareForm:"HardwareFormValidation",
		hardware_form_showcase:"HardwareFormShowcaseResponse",
		hardware_form_draft:"String",
		installation_mirror_upload:"MirrorUploadResponse",
		project_update_shopping_cart:"ID",
		project_proposal_update:"Boolean",
		processEnergyAccountAnalysis:"EnergyBillAnalysisDecision"
	},
	DocumentFormPfResponse:{
		id:"ID",
		status:"String",
		project_id:"ID",
		installation_document_matches_bill:"Boolean",
		installation_address_matches_bill:"Boolean",
		identity_matches_document:"Boolean",
		reason:"String",
		documents:"DocumentForm",
		comments:"String"
	},
	DocumentFormPjResponse:{
		id:"ID",
		status:"String",
		project_id:"ID",
		installation_document_matches_bill:"Boolean",
		installation_address_matches_bill:"Boolean",
		identity_matches_document:"Boolean",
		incorporation_document_matches_document:"Boolean",
		legal_representant_missing:"Boolean",
		reason:"String",
		documents:"DocumentForm",
		comments:"String"
	},
	DocumentForm:{
		id:"ID",
		type:"String",
		url:"String",
		url_inline:"String",
		filename:"String",
		mimetype:"String",
		size:"Int",
		document_storage_id:"String",
		created_at:"String"
	},
	FormalizationStatus:{
		contract:"SectionStatus",
		hardware:"SectionStatus",
		registration:"SectionStatus",
		documentation:"SectionStatus",
		receipt_model:"SectionStatus"
	},
	SectionStatuses:{
		concluded:"SectionStatus",
		simulation:"SectionStatus",
		installation:"SectionStatus",
		formalization:"FormalizationStatus"
	},
	Payment:{
		id:"Int",
		financing_id:"Float",
		value:"Float",
		total_value:"Float",
		payment_percentage:"Float",
		created_at:"String"
	},
	Financing:{
		id:"Int",
		project_id:"String",
		financed_value:"Float",
		customer_name:"String",
		document:"String",
		expires_at:"String",
		created_at:"String",
		formalized_at:"String",
		installed_at:"String",
		status:"FinancingStatus",
		step:"String",
		is_combo:"Boolean",
		payments:"Payment",
		flow_provider:"String",
		person_type:"PersonType",
		all_payments_sent:"Boolean",
		section_statuses:"SectionStatuses",
		updated_at:"String",
		is_expired:"Boolean",
		system_power:"Float",
		new_journey:"Boolean",
		openable_steps:"FormalizationStatuses"
	},
	FormalizationStatuses:{
		registration:"Boolean",
		documentation:"Boolean",
		hardware:"Boolean",
		contract:"Boolean",
		receiptModel:"Boolean"
	},
	FinancingListTotalByStep:{
		simulation:"Int",
		formalization:"Int",
		installation:"Int",
		concluded:"Int",
		expired:"Int"
	},
	FinancingListTotalByStatus:{
		pending:"Int",
		expired:"Int",
		reproved:"Int",
		approved:"Int",
		under_analysis:"Int",
		pendency:"Int",
		send_contract:"Int",
		pending_customer:"Int",
		pending_receipt:"Int",
		concluded:"Int",
		canceled:"Int",
		kit_pending:"Int"
	},
	FinancingsList:{
		data:"Financing",
		total:"Int",
		total_by_step:"FinancingListTotalByStep",
		total_by_status:"FinancingListTotalByStatus"
	},
	FinancingType:{
		max_financing_value:"Float",
		min_financing_value:"Float",
		max_financing_installments:"Int",
		grace_period_financing:"GracePeriod",
		max_bnpl_value:"Float",
		max_bnpl_installments:"Int",
		grace_period_bnpl:"GracePeriod"
	},
	GracePeriod:{
		label:"String",
		value:"Int"
	},
	Status:{
		description:"String",
		id:"Int",
		slug:"DocumentStatusType"
	},
	Document:{
		filename:"String",
		filetype:"String",
		id:"String",
		status:"Status",
		type:"String",
		url:"String"
	},
	Section:{
		documents:"Document",
		id:"String",
		status:"Status",
		type:"TypeSection"
	},
	InstallationStatus:{
		description:"String",
		id:"Int",
		slug:"FileStatusType"
	},
	Installation:{
		comment:"String",
		concluded_at:"String",
		grade:"Int",
		has_ampera:"Boolean",
		has_ampera_activated:"Boolean",
		has_minimal_projects:"Boolean",
		id:"String",
		new_journey:"Boolean",
		plant_id:"String",
		plant_url:"String",
		project_id:"String",
		project_name:"String",
		sections:"Section",
		status:"InstallationStatus"
	},
	ValidateHardwareFormPricePerWatt:{
		is_valid:"Boolean",
		is_under_expected:"Boolean"
	},
	ValidateHardwareFormModules:{
		price_per_watt:"ValidateHardwareFormPricePerWatt"
	},
	ValidateHardwareFormOverload:{
		is_valid:"Boolean",
		is_under_expected:"Boolean"
	},
	ValidateHardwareFormInverters:{
		overload:"ValidateHardwareFormOverload"
	},
	ValidateHardwareFormHardware:{
		modules:"ValidateHardwareFormModules",
		inverters:"ValidateHardwareFormInverters"
	},
	HardwareFormValidation:{
		is_valid:"Boolean",
		hardware:"ValidateHardwareFormHardware"
	},
	HardwareFormShowcaseResponse:{
		overload_is_valid:"Boolean",
		price_per_watt_is_valid:"Boolean",
		percentage_of_service_with_combo_is_valid:"Boolean"
	},
	MirrorStatus:{
		status:"MirrorStatuses"
	},
	MirrorUploadResponse:{
		id:"String",
		url:"String"
	},
	LegacyFinancing:{
		projectId:"String",
		financingId:"Int",
		formalizedAt:"String",
		stage:"String",
		status:"String"
	},
	CorePartner:{
		id:"Int",
		cnpj:"String",
		responsible:"CoreParterResponsible",
		company_name:"String",
		fantasy_name:"String",
		status:"CoreParterStatus"
	},
	CoreParterResponsible:{
		id:"Int",
		full_name:"String"
	},
	CoreParterStatus:{
		active:"Boolean",
		reason:"String",
		date:"String"
	},
	ProjectProposal:{
		value_changed:"Boolean"
	},
	AppliedModifierOption:{
		type:"String",
		value:"String"
	},
	AppliedModifier:{
		name:"String",
		type:"String",
		options:"AppliedModifierOption"
	},
	AddonProduct:{
		id:"ID",
		product_name:"String",
		product_external_id:"String",
		simulation_id:"String",
		type:"String",
		product_slug:"String",
		applied:"Boolean",
		partner_commission:"Float",
		installment_price:"Float",
		total_price:"Float",
		monthly_interest_rate:"Float",
		engine_simulation_id:"String",
		previous_engine_simulation_id:"String",
		cet:"Float",
		applied_modifiers:"AppliedModifier"
	},
	SelectedInstallments:{
		cet:"String",
		iof:"Float",
		ipca:"String",
		addons:"AddonProduct",
		aggregated_addons:"AddonProduct",
		user_id:"Int",
		document:"String",
		is_combo:"Boolean",
		commission:"Float",
		partner_id:"Int",
		aliquot_iof:"Float",
		new_journey:"Boolean",
		person_type:"String",
		down_payment:"Float",
		grace_period:"Int",
		installments:"Int",
		project_name:"String",
		system_power:"Float",
		interest_rate:"Float",
		cet_percentage:"Float",
		commission_raw:"Float",
		financing_value:"Float",
		taxa_de_cadastro:"Float",
		installment_value:"Float",
		is_from_suggested:"Boolean",
		registration_fee_gross:"Float",
		registration_fee_raw_dry:"Float",
		registration_fee_raw_final:"Float"
	},
	RiskInfo:{
		title:"String",
		description:"String",
		todo:"String",
		reason:"String",
		decision:"Int"
	},
	BNPL:{
		decision_pre_analysis:"Int",
		min_entry_value:"Float",
		max_installments:"Int",
		pre_approved_value:"Float",
		status:"String"
	},
	Project:{
		id:"ID",
		user_id:"Int",
		partner_id:"Int",
		person_type:"String",
		document:"String",
		created_at:"String",
		representative_document:"String",
		project_value:"Float",
		system_power:"Float",
		grace_period:"Int",
		down_payment:"Float",
		status:"String",
		project_status:"String",
		max_installments:"Int",
		max_grace_period:"Int",
		project_name:"String",
		project_state:"String",
		partner_profile:"String",
		pre_approved_value:"Float",
		products_applieds:"String",
		installments:"String",
		risk_info:"RiskInfo",
		state_registration:"String",
		complete:"Boolean",
		selected_installments:"SelectedInstallments",
		birth_date:"String",
		decision_pre_analysis:"Int",
		score_sol_facil:"Int",
		simulation_id:"String",
		addons:"AddonProduct",
		max_approved_project_value:"Float",
		min_approved_project_value:"Float",
		max_financing_value:"Float",
		border_line:"Boolean",
		is_formalized:"Boolean",
		new_journey:"Boolean",
		formalized_at:"String",
		shopping_cart_id:"String",
		bnpl:"BNPL",
		bnpl_simulation_id:"String",
		bnpl_status:"String",
		is_onflex_partner:"Boolean",
		can_select_ampera:"Boolean",
		has_energy_bill_analysis:"Boolean",
		insurance_commission:"Float",
		gained_points:"Int",
		original_installment_value:"Float",
		is_contract_signed:"Boolean"
	},
	ProjectProposalUpdateResponse:{
		engine_simulation_id:"String",
		simulation_id:"String",
		project_id:"String",
		is_combo:"Boolean",
		is_ampera:"Boolean",
		commission:"Int",
		applied_addons:"String"
	},
	IsComboResponse:{
		data:"Boolean"
	},
	EnergyBillAnalysisDecision:{
		decision:"String",
		details:"String"
	},
	GetOpportunitiesResponse:{
		addons_opportunities:"AddonsOpportunities",
		financing_gained_points:"Int"
	},
	AddonsOpportunities:{
		addon_type:"String",
		gained_points:"Int",
		product_slug:"String"
	},
	LevelBlackStatus:{
		kwGoalMonth:"Int",
		kwReachedMonth:"Int"
	},
	Contact:{
		landline:"String",
		mobile:"String",
		email:"String"
	},
	Address:{
		street:"String",
		number:"String",
		complement:"String",
		reference:"String",
		district:"String",
		zipcode:"String",
		city:"String",
		state:"String"
	},
	Responsible:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner"
	},
	Permission:{
		store:"Boolean",
		financing:"Boolean"
	},
	Score:{
		current:"Int",
		goal:"Int"
	},
	Downgrade:{
		has_risk:"Boolean"
	},
	Sfplus:{
		level:"Int",
		score:"Score",
		black_status:"LevelBlackStatus",
		downgrade:"Downgrade",
		is_level_black:"Boolean",
		next_transition_date:"String"
	},
	Partner:{
		id:"Int",
		corporate_name:"String",
		operation_term_accepted:"Boolean",
		trade_name:"String",
		document:"String",
		category:"String",
		state_registration:"String",
		active:"Boolean",
		created_at:"String",
		address:"Address",
		responsible:"Responsible",
		permission:"Permission",
		users:"User",
		sfplus:"Sfplus"
	},
	Post:{
		id:"Int",
		title:"String",
		body:"String",
		userId:"String",
		comments:"Comment"
	},
	Comment:{
		id:"Int",
		name:"String",
		email:"String",
		body:"String"
	},
	User:{
		id:"Int",
		username:"String",
		profile:"String",
		fullname:"String",
		document:"String",
		birthdate:"String",
		active:"Boolean",
		created_at:"String",
		contact:"Contact",
		partner:"Partner",
		posts:"Post"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}
<script lang="ts" setup>
import type { User } from '~/utils/auth/User'
import type { Project } from '~/utils/project/Project'
import { useDownload, useToast } from '@solfacil/girassol'
import InstallationService from '~/services/installation/Installation'
import SimulatorService from '~/services/simulator/Simulator'
import useOpenableSteps from '~/store-v2/financings/useOpenableSteps'
import useDraftMutation from '~/store-v2/hardware/useDraftMutation'
import useHardwareForm from '~/store-v2/hardware/useHardwareForm'
import useHardwareValidation from '~/store-v2/hardware/useHardwareValidation'
import useShowcaseMutation from '~/store-v2/hardware/useShowcaseMutation'
import useGetShoppingCartId from '~/store-v2/simulation/useGetShoppingCartId'
import useShoppingCartIdMutation from '~/store-v2/simulation/useShoppingCartIdMutation'
import filtersStoreMarketFinancing from '~/utils/filtersStoreMarketFinancing'
import { type HardwareBodyDraft, type HardwareBodySave, HardwareMirrorStatus } from '~/utils/installation/Installation'

const props = withDefaults(defineProps<{
  projectId: string
  disabled?: boolean
}>(), {
  disabled: false,
})

const emit = defineEmits<{
  (e: 'onKitOrigin', origin: KitOrigins): void
  (e: 'onComboSelected', kit: OnComboSelected): void
}>()

interface OnComboSelected {
  selected: boolean
}

enum KitOrigins {
  MIRROR = 'mirror',
  SHOWCASE = 'showcase',
}

const kitOrigin = ref<KitOrigins>(KitOrigins.MIRROR)
const project = ref<Project>()
const isSelectedKitOut = ref(false)
const showInvalidKitAlert = ref(false)
const mirrorFile = ref<File[]>()
const mirrorUploadFail = ref<string>()
const isLoadingMirrorUpload = ref(false)
const isLoadingProjectData = ref(false)
const projectId = ref(props.projectId)

const { t } = useI18n()
const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const appStore = useAppStorage()
const CHANNEL = 'plugin-financiamento'
const ENV = useEnvironment()
const { rest } = useConnections()
const { createErrorToast, createInformativeToast } = useToast()
const { downloadFile } = useDownload()

const { data: hardwareForm, isLoading: isLoadingHardwareForm, refetch: refetchHardwareForm } = useHardwareForm(projectId)
const { data: shoppingCartId, isLoading: isLoadingShoppingCardId } = useGetShoppingCartId(projectId.value)

const { mutate: showcaseMutation } = useShowcaseMutation()
const { mutate: updateShoppingCartId } = useShoppingCartIdMutation()
const { mutateAsync: draftMutation } = useDraftMutation()
const { mutateAsync: hardwareValidation, data: hardwareValidationResult } = useHardwareValidation()
const {
  refetch: refetchDataStepsStatus,
} = useOpenableSteps(projectId)

const simulatorService = new SimulatorService(useApi('simulator'))
const installationService = new InstallationService(useApi('installation'))

const user = appStore.get<User>('user')
const selectKitOrigin = [
  { name: KitOrigins.MIRROR, label: t('project_data.form.mirror'), value: KitOrigins.MIRROR },
  { name: KitOrigins.SHOWCASE, label: t('project_data.form.showcase'), value: KitOrigins.SHOWCASE },
]

const loading = computed(() => isLoadingHardwareForm.value || isLoadingProjectData.value || isLoadingShoppingCardId.value)
const hasStoreOrder = computed(() => Boolean(hardwareForm.value?.has_store_order))
const isFormBlocked = computed(() => {
  return props.disabled || hasStoreOrder.value
})
const showMirrorUpload = computed(() => {
  const isMirrorNullable = hardwareForm.value?.mirror_metadata === null
  const isMirrorReproved = hardwareForm.value?.mirror_metadata?.status === HardwareMirrorStatus.REPROVED

  return Boolean(isMirrorNullable || isMirrorReproved)
})
const showMirrorUploadedFile = computed(() => {
  const hasDocumentUrl = Boolean(hardwareForm.value?.mirror_metadata?.document_url)
  const isNotReproved = hardwareForm.value?.mirror_metadata?.status !== HardwareMirrorStatus.REPROVED
  const isNotPending = hardwareForm.value?.mirror_metadata?.status !== HardwareMirrorStatus.PENDING

  return hasDocumentUrl && (isNotReproved && isNotPending)
})
const showAlertErrorHardware = computed(() => {
  const isReproved = hardwareForm.value?.mirror_metadata?.status === HardwareMirrorStatus.REPROVED
  const hasReason = Boolean(hardwareForm.value?.mirror_metadata?.reason)

  return isReproved && hasReason
})

async function getProject() {
  try {
    isLoadingProjectData.value = true
    const { data } = await simulatorService.get_project(props.projectId)
    project.value = data
  }
  catch (error) {
    console.error(error)
    createErrorToast('Não foi possível carregar as informações do projeto. Por favor, tente novamente.')
  }
  finally {
    isLoadingProjectData.value = false
  }
}

async function postKitDraft(draft: HardwareBodyDraft) {
  try {
    if (!draft)
      return

    await draftMutation(draft)
  }
  catch {
    createErrorToast(t('project_data.errors.failed_to_save_kit_draft'))
  }
}

function transformKitToDraft(kit): HardwareBodyDraft {
  const inverters = kit.inverters.map((inverter) => {
    return {
      quantity: inverter.amount,
      power: inverter.power,
      sku: inverter.sku,
      brand: inverter.brand,
      model: inverter.model,
    }
  })
  const modules = kit.modules.map((module) => {
    return {
      quantity: module.amount,
      power: (kit.power * 1000) / module.amount,
      sku: module.sku,
      brand: module.brand,
      model: module.model,
    }
  })

  return {
    project_id: props.projectId,
    inverters,
    modules,
    price: kit.price,
    sku: kit.sku,
    power: kit.power,
  }
}

function getHardwareFormFromDraft(draft: HardwareBodyDraft) {
  const inverters = draft.inverters?.map(item => ({ id: Number(item.sku), quantity: item.quantity, power: String(item.power) })) || []
  const modules = draft.modules?.map(item => ({ id: Number(item.sku), quantity: item.quantity, power: String(item.power) })) || []

  const hardwareDataForm: HardwareBodySave = {
    is_combo: true,
    project_id: props.projectId,
    distributor_id: 0,
    phase_id: 0,
    inverters,
    modules,
    id: shoppingCartId.value,
    combo_price: draft.price,
  }

  return hardwareDataForm
}

async function validateDraft(draft: HardwareBodyDraft) {
  if (!draft) {
    return false
  }

  const body = getHardwareFormFromDraft(draft)
  await hardwareValidation(body)

  return hardwareValidationResult.value?.is_valid
}

async function onAddKit(data: { cartId: any, kit: any }) {
  updateShoppingCartId({ projectId: props.projectId, cartId: String(data.cartId) })

  createInformativeToast({
    title: t('toastKitSelected.title'),
    description: t('toastKitSelected.description'),
  })

  const _draft = transformKitToDraft(data.kit)

  await postKitDraft(_draft)

  isSelectedKitOut.value = false

  const isValid = await validateDraft(_draft)

  if (isValid) {
    showcaseMutation({
      price: _draft.price,
      project_id: props.projectId,
      inverters: _draft.inverters,
      modules: _draft.modules,
      shopping_cart_id: shoppingCartId.value || '',
    })

    emit('onComboSelected', { selected: true })
  }

  showInvalidKitAlert.value = !isValid
}

async function onDeleteKit() {
  await updateShoppingCartId({ projectId: props.projectId, cartId: '' })

  showInvalidKitAlert.value = false

  const payload = {
    project_id: props.projectId,
  }

  await postKitDraft(payload)
}

async function onLoadCart(result) {
  if (result?.items?.length > 0) {
    const hasUnavailableKitItem = result.items.some(item => item.status.description === 'unavailable')

    if (hardwareForm.value?.combo_metadata) {
      const isValid = await validateDraft(hardwareForm.value!.combo_metadata!)

      isSelectedKitOut.value = hasUnavailableKitItem
      showInvalidKitAlert.value = !isValid
    }
  }
}

function handleMirrorDownload() {
  if (hardwareForm.value?.mirror_metadata?.document_url)
    downloadFile(hardwareForm.value.mirror_metadata.document_url)
}

function handleRemoveMirror() {
  mirrorFile.value = undefined
}

async function pullingMirrorStatus() {
  const interval = setInterval(async () => {
    const { data } = await installationService.get_mirror_status(props.projectId)

    if (data.status === HardwareMirrorStatus.APPROVED) {
      clearInterval(interval)

      await refetchHardwareForm()

      refetchDataStepsStatus()
    }
  }, 15000) // 15 seconds
}

async function submitMirror() {
  try {
    isLoadingMirrorUpload.value = true
    if (mirrorFile.value === undefined)
      return

    mirrorUploadFail.value = undefined

    await installationService.post_mirror({
      projectId: props.projectId,
      mirror: mirrorFile.value[0],
    })

    await refetchHardwareForm()

    createInformativeToast({
      description: t('project_data.notifications.mirror_uploaded'),
    })

    pullingMirrorStatus()
  }
  catch (error) {
    console.error(error)
    mirrorUploadFail.value = t('project_data.notifications.mirror_upload_error')

    createErrorToast({
      description: t('project_data.notifications.mirror_upload_error'),
    })
  }
  finally {
    isLoadingMirrorUpload.value = false
  }
}

async function checkComboMetadata(metadata: HardwareBodyDraft) {
  const isValid = await validateDraft(metadata)
  showInvalidKitAlert.value = hardwareForm.value?.mirror_metadata !== null ? false : !isValid
}

function openSolfacilStore() {
  const urlSolfacilStore = 'https://loja.solfacil.com.br/'

  return window.open(urlSolfacilStore, '_blank')
}

function handleWithHardwareFormState() {
  if (!hardwareForm.value)
    return

  if (hardwareForm.value.combo_metadata)
    checkComboMetadata(hardwareForm.value.combo_metadata)

  if (hardwareForm.value.is_showcase)
    kitOrigin.value = KitOrigins.SHOWCASE

  if (hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.REPROVED)
    mirrorUploadFail.value = t('project_data.notifications.mirror_uploaded_reproved')

  if (hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.PENDING)
    pullingMirrorStatus()

  const isMirrorApproved = hardwareForm.value.mirror_metadata?.status === HardwareMirrorStatus.APPROVED

  if (isMirrorApproved || hardwareForm.value.is_showcase)
    emit('onComboSelected', { selected: true })
}

watch(mirrorFile, (newValue) => {
  if (newValue)
    submitMirror()
})

watch(hardwareForm, () => {
  handleWithHardwareFormState()
})

watch(kitOrigin, () => {
  emit('onKitOrigin', kitOrigin.value)
})

onMounted(() => {
  getProject()
  handleWithHardwareFormState()

  emit('onKitOrigin', kitOrigin.value)
})
</script>

<template>
  <div class="title mb-4">
    <span class="label text-[16px] md:system:text-[20px] pb-0 font-highlight text-neutral-low-dark">
      {{ t('project_data.heading.kit') }}
    </span>
  </div>

  <SolAlert
    v-if="isSelectedKitOut" id="alert-kit-out" class="mb-2" feedback="warning"
    :title="t('project_data.alerts.kit_out.title')" :text="t('project_data.alerts.kit_out.text')"
  />

  <SolAlert
    v-if="showInvalidKitAlert" id="alert-invalid-kit" class="mb-2" feedback="warning"
    :title="t('project_data.alerts.invalid_kit.title')" :text="t('project_data.alerts.invalid_kit.text')"
  />

  <SolAlert v-if="showAlertErrorHardware" id="informative-feedback-receipt-model" class="my-3" feedback="error">
    {{ hardwareForm?.mirror_metadata?.reason }}
  </SolAlert>

  <div
    class="bg-neutral-high-medium bg-opacity-intense p-4 mb-4 rounded-md"
  >
    <label for="kit-origin" class="fonts-body-medium-bold text-neutral-low-dark">
      {{ t('project_data.form.combo_option.label') }}
    </label>
    <SolRadioGroup
      id="kit-origin"
      v-model="kitOrigin"
      class="mb-2"
      title=""
      name="mirror"
      :direction="isLargeScreen ? 'row' : 'column'"
      :radios="selectKitOrigin"
      :disabled="isFormBlocked"
    />
    <p class="fonts-subtitle-small px-4">
      {{ t('project_data.form.combo_option.subtitle') }}
    </p>
  </div>

  <!-- Mirror section -->
  <template v-if="kitOrigin === KitOrigins.MIRROR">
    <template v-if="showMirrorUpload">
      <SolAlert
        id="mirror-store-alert"
        class="mb-2"
        feedback="informative"
        :text="t('project_data.alerts.mirror.info_payment_method_store')"
        :action="t('project_data.alerts.mirror.info_payment_method_store_button')"
        @click="openSolfacilStore()"
      />

      <SolFileUpload
        id="mirror-upload"
        v-model="mirrorFile"
        name="mirror-upload"
        :multiple="false"
        :file-name="mirrorFile?.[0]?.name"
        :label="t('project_data.form.mirror_upload')"
        :error="mirrorUploadFail"
        :loading="isLoadingMirrorUpload"
        :download-menu="['download', 'delete']"
        :class="{ 'pointer-events-none opacity-60': isFormBlocked }"
        :disabled="isFormBlocked"
        @menu:download="handleMirrorDownload"
        @menu:delete="handleRemoveMirror"
      />
    </template>
    <template v-if="showMirrorUpload === false && !showMirrorUploadedFile">
      <div>
        <span class="font-bold text-3xs text-neutral-low-dark">{{ t('project_data.form.mirror_upload') }}</span>
        <SharedMessageStatus
          :file-type-icon="['pdf']"
          :file-name="['Espelho-do-pedido.pdf']"
          :status-type-document="hardwareForm?.mirror_metadata?.status"
        />
      </div>
    </template>
    <UploadedFile
      v-if="showMirrorUploadedFile"
      :text="t('project_data.alerts.mirror_uploaded.text')"
      :action="{ text: t('project_data.alerts.mirror_uploaded.action_text') }"
      :download="{ link: hardwareForm?.mirror_metadata?.document_url || '' }"
    />
  </template>

  <!-- Showcase section -->
  <template v-if="kitOrigin === KitOrigins.SHOWCASE">
    <StoreMarketFinancing
      v-if="!loading && project"
      class="!px-0"
      :class="{ 'pointer-events-none': isFormBlocked }"
      :disable-filters="isFormBlocked"
      :disable-cart="isFormBlocked"
      title="Kits disponíveis no Combo Fácil"
      subtitle="Sugestões da nossa loja de acordo com as dimensões do seu projeto."
      :filters="filtersStoreMarketFinancing.createObject(`${(project.system_power)}`, project.project_state)"
      :channel="CHANNEL"
      :environment="ENV"
      :segmentation-id="project.id"
      :partner-id="user?.parceiro?.id"
      :cart-id="shoppingCartId"
      mix-panel-env="formalizing-equipments"
      :mix-panel-key="rest.mixpanel.key"
      @on-add-kit="onAddKit"
      @on-delete-kit="onDeleteKit"
      @on-load-cart="onLoadCart"
    />
  </template>
</template>

import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import { getHardwareForm } from '~/services-v2/installation-api/installation-api'

export default function useHardwareForm(projectId: Ref<string>) {
  const queryKey = ref(queryKeys.HARDWARE.FORM(projectId.value))

  const queryFn = async () => {
    const res = await getHardwareForm(projectId.value)
    return res
  }

  const query = useQuery({
    queryFn,
    queryKey,
    refetchOnWindowFocus: true,
    staleTime: 30_000, // 30s
  })

  watch(projectId, () => {
    queryKey.value = queryKeys.HARDWARE.FORM(projectId.value)
    query.refetch()
  })

  return query
}

export type UseHardwareForm = ReturnType<typeof useHardwareForm>

export type UseHardwareFormParams = Parameters<typeof useHardwareForm>[0]['value']

export type UseHardwareFormData = UseHardwareForm['data']['value']

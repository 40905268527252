import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import customerRegisterApi from '~/services-v2/customer-register-api/customer-register-api'

export default function useCustomerRegistration(id: Ref<string>) {
  const queryKey = computed(() => queryKeys.REGISTRATION.CUSTOMER(id.value))

  const queryFn = async () => {
    const res = await customerRegisterApi.getCustomerRegistrationForm(id.value)
    return res.data
  }

  const query = useQuery({
    queryFn,
    queryKey,
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  watch([id], () => {
    query.refetch()
  })

  return query
}

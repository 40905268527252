import { useMutation } from '@tanstack/vue-query'
import queryClient, { queryKeys } from '~/queryClient'
import financialBff from '~/services-v2/financial-bff/financial-bff'

interface UseShoppingCartIdMutationInput {
  projectId: string
  cartId: string
}

export default function useShoppingCartIdMutation() {
  const queryKey = queryKeys.SIMULATION.PUT_SHOPPING_CART_ID

  const mutationFn = async (input: UseShoppingCartIdMutationInput) => {
    await financialBff('mutation')({
      project_update_shopping_cart: [
        { project_id: input.projectId, shopping_cart_id: input.cartId },
        true,
      ],
    })
  }

  return useMutation({
    mutationKey: queryKey,
    mutationFn,
    retry: 3,
    onSuccess: (_, variables) => {
      const shoppingCartIdQueryKey = queryKeys.SIMULATION.GET_SHOPPING_CART_ID(variables.projectId)

      queryClient.setQueryData(shoppingCartIdQueryKey, () => variables.cartId)

      queryClient.invalidateQueries({
        queryKey: shoppingCartIdQueryKey,
      })
    },
  })
}

import SecureLS from 'secure-ls'

export function useAppStorage() {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: 'aes',
    encryptionSecret: import.meta.env.VITE_STORAGE_SECRET,
    encryptionNamespace: 'sol_',
  })

  return {
    set: (key: string, value: unknown) => {
      ls.set(key, value)
    },
    get: <T>(key: string) => ls.get(key) as T,
    remove: (key: string): void => {
      ls.remove(key)
    },
    clear: () => {
      ls.clear()
    },
  }
}

<!-- eslint-disable unused-imports/no-unused-vars -->
<script setup lang="ts">
import Santander from '~/pages/customer/santander/index.vue'
import Solfacil from '~/pages/customer/solfacil/index.vue'
import useFinancing from '~/store-v2/financings/useFinancing'
import useHardwareForm from '~/store-v2/hardware/useHardwareForm'
import useProject from '~/store-v2/projects/useProject'
import useCustomerRegistration from '~/store-v2/registration/useCustomerRegistration'
import SimulationResumeWidget from '~/ui-blocks/SimulationResumeWidget/SimulationResumeWidget.vue'
import { ProviderEnum } from '~/utils/customer-register/CustomerRegister'
import { getErrorMessage } from '~/utils/errors'
import verify from '~/utils/verify'

// base hooks
const { track } = useMixpanel()
const router = useRouter()

// initial variables and computed
const projectId = ref(router.currentRoute.value.params.id as string)

const providersOptions = {
  [ProviderEnum.SANTANDER]: Santander,
  [ProviderEnum.SOLFACIL]: Solfacil,
}

// domain hooks
const {
  data: financing,
  error: errorFinancing,
  isError: isErrorFinancing,
  isLoading: isLoadingFinancing,
  refetch: refetchFinancing,
} = useFinancing(projectId)

const {
  data: project,
  isLoading: isLoadingProject,
  error: errorProject,
  isError: isErrorProject,
  refetch: refetchProject,
} = useProject(projectId.value)

const {
  data: customerRegistration,
  isError: isErrorCustomerRegistration,
  isLoading: isLoadingCustomerRegistration,
  refetch: refetchCustomerRegister,
  error: errorCustomerRegistration,
} = useCustomerRegistration(projectId)

const {
  data: hardwareForm,
  refetch: refetchHardwareForm,
  isLoading: isLoadingHardwareForm,
  isError: isErrorHardwareForm,
  error: errorHardwareForm,
} = useHardwareForm(projectId)

// functions
async function refetchAll() {
  await refetchCustomerRegister()
  await refetchHardwareForm()
  await refetchFinancing()
  await refetchProject()
}

// derived states
const registerProvider = computed(() => customerRegistration.value?.provider.name || 'solfacil')
const isOnflexPartner = computed(() => project?.value?.is_onflex_partner || false)
const hasEnergyBillAnalysis = computed(() => project?.value?.has_energy_bill_analysis || false)

const isLoading = computed(() => isLoadingCustomerRegistration.value || isLoadingProject.value || isLoadingFinancing.value || isLoadingHardwareForm.value)
const isError = computed(() => isErrorCustomerRegistration.value || isErrorProject.value || isErrorFinancing.value || isErrorHardwareForm.value)
const error = computed(() => getErrorMessage([errorFinancing.value, errorHardwareForm.value, errorProject.value, errorCustomerRegistration.value]))

// lifecycle
onMounted(async () => {
  track('customer_data_page_view', { trigger: 'Ver a página Dados do cliente' })
})

// watchs
watch(() => project.value, (data) => {
  if (data) {
    verify.oldJourneyFinancingListRedirect(data.new_journey || false)
  }
})
</script>

<template>
  <!-- loading state -->
  <div v-if="isLoading" class="container">
    <div class="my-4 card-container flex flex-col justify-between w-full">
      <span class="loader-project" />
    </div>
  </div>

  <div v-else>
    <!-- error state -->
    <template v-if="isError">
      <SharedErrorsCheckAccess :error-message="error" />
    </template>

    <!-- success state -->
    <template v-else>
      <div v-if="customerRegistration">
        <component
          :is="providersOptions[registerProvider]"
          :person-form="customerRegistration.person_form"
          :plant-address="customerRegistration.plant_address_form"
          :residential-address="customerRegistration.residential_address_form"
          :installation-document="customerRegistration.installation_document"
          :provider="registerProvider"
          :status="customerRegistration.status"
          :trigger-refetch="refetchAll"
          :is-onflex-partner="isOnflexPartner"
          :has-energy-bill-analysis="hasEnergyBillAnalysis"
          :hardware-form="{ response: hardwareForm, refetch: refetchHardwareForm }"
        />
      </div>
    </template>
  </div>

  <!-- Floating Widget -->
  <SimulationResumeWidget />
</template>

<style lang="scss">
.container {
  @apply flex flex-col items-center px-4xs pb-6 mt-6;

  @screen md:system {
    @apply p-12 mt-0;
  }
}

.loader-project {
  @apply h-96 rounded-lg;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9) url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat center center;
}
</style>

<route lang="yaml">
meta:
  layout: client-data
</route>

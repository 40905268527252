import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import type { HardwareBodyDraft, HardwareBodySave, HardwareCombos, HardwareCombosModel, HardwareMirrorStatusResponse, HardwareMirrorUpload, HardwareValidation, PowerRange, ResponseHardwareSave, ResponseInstallation } from '~/utils/installation/Installation'
import BaseService from '../BaseService'

interface DefaultResponse<T> {
  data: T
}

export default class InstallationService extends BaseService {
  async post_send_installation_hardware(id: string, files: FormData, comment: string): Promise<
    ResponseModel<ResponseInstallation>
  > {
    try {
      const uri = `/installations/upload?id=${id}&comment=${comment}`
      const encoded = encodeURI(uri)
      const response = await super.api<ResponseModel<ResponseInstallation>>(`${encoded}`, {
        method: 'POST',
        body: files,
      })

      return response
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  // HARDWARE
  async get_hardware_model(projectID: string): Promise<HardwareCombosModel> {
    try {
      const response = await super.api<HardwareCombosModel>(`/hardware/form/${projectID}`, {
        method: 'GET',
      })
      return response
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_combos_hardware(projectID: string): Promise<ResponseModel<HardwareCombos>> {
    try {
      const response = await super.api<ResponseModel<HardwareCombos>>
      (`/hardware/combos?microinverters=true&inverters=true&modules=true&phase=true&project_id=${projectID}`, {
        method: 'GET',
      })
      return response
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async get_power_range_hardware(): Promise<ResponseModel<PowerRange>> {
    try {
      const response = await super.api<ResponseModel<PowerRange>>('/hardware/power/ranges', {
        method: 'GET',
      })
      return response
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async post_save_hardware(hardwareForm: HardwareBodySave): Promise<ResponseModel<ResponseHardwareSave>> {
    try {
      const response = await super.api<ResponseModel<ResponseHardwareSave>>('/hardware/save', {
        method: 'POST',
        body: hardwareForm,
      })

      return response
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  async post_hardware_draft(hardwareForm: HardwareBodyDraft): Promise<void> {
    await super.api('/hardware/draft', {
      method: 'POST',
      body: hardwareForm,
    })
  }

  async post_mirror({ projectId, mirror }: { projectId: string, mirror: File }): Promise<HardwareMirrorUpload> {
    const formData = new FormData()

    formData.append('mirror', mirror)

    const response = await super.api<HardwareMirrorUpload>(`/v1/mirror/upload/${projectId}`, {
      method: 'POST',
      body: formData,
    })

    return response
  }

  async get_mirror_status(projectId: string): Promise<DefaultResponse<HardwareMirrorStatusResponse>> {
    const response = await super.api<DefaultResponse<HardwareMirrorStatusResponse>>(`/v1/mirror/status/${projectId}`, {
      method: 'GET',
    })

    return response
  }

  async validate_kit(hardware: HardwareBodySave) {
    const response = await super.api<DefaultResponse<HardwareValidation>>('/hardware/validations', {
      method: 'POST',
      body: hardware,
    })

    const { data } = response

    return {
      isValid: data.is_valid,
      hardware: {
        modules: {
          pricePerWatt: {
            isValid: data.hardware.modules.price_per_watt.is_valid,
            isUnderExpected: data.hardware.modules.price_per_watt.is_under_expected,
          },
        },
        inverters: {
          overload: {
            isValid: data.hardware.inverters.overload.is_valid,
            isUnderExpected: data.hardware.inverters.overload.is_under_expected,
          },
        },
      },
    }
  }
}

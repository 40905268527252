<script setup lang="ts">
import { useI18n } from 'vue-i18n'

defineProps({
  errorMessage: {
    type: String,
    required: true,
  },
})
const router = useRouter()
const { t } = useI18n()

function goToNewPage() {
  router.push('/simulation/new')
}

function goToListPage() {
  router.push('/')
}
</script>

<template>
  <div class="my-4 card-container flex flex-col justify-between w-full items-center py-20">
    <SolEmptyState
      v-if="errorMessage === 'NOT_FOUND'"
      id="notFound"
      :title="t('notFound.title')"
      :subtitle="t('notFound.description')"
      variant="error404"
      class="!max-w-full !md:system:max-w-[550px]"
    />
    <SolEmptyState
      v-if="errorMessage === 'UNAUTHORIZED'"
      id="unauthorized"
      :title="t('unauthorized.title')"
      :subtitle="t('unauthorized.description')"
      variant="no-content"
      class="!max-w-full !md:system:max-w-[550px]"
    />
    <SolEmptyState
      v-if="errorMessage === 'UNKNOWN'"
      id="unknown"
      :title="$t('unknonwError.title')"
      :subtitle="$t('unknonwError.description')"
      variant="no-content"
      class="!max-w-full !md:system:max-w-[550px]"
    />
    <SolEmptyState
      v-else
      id="default"
      :title="$t('unknonwError.title')"
      :subtitle="$t('unknonwError.description')"
      variant="no-content"
      class="!max-w-full !md:system:max-w-[550px]"
    />
    <div class="md:site:flex">
      <SolButton
        id="btn-open-list"
        variant="secondary"
        class="w-full mb-micro md:site:mx-4xs"
        size="medium"
        @click="goToListPage"
      >
        {{ t('simulation.golist') }}
      </SolButton>
      <SolButton
        id="btn-new-simulation"
        class="w-full whitespace-nowrap"
        variant="primary"
        size="medium"
        @click="goToNewPage"
      >
        <template #icon-left>
          <IconPlus />
        </template>
        {{ t('simulation.new') }}
      </SolButton>
    </div>
  </div>
</template>

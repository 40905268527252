import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import customerRegisterService from '~/services-v2/customer-register-api/customer-register-api'

export default function useCompanyRegistration(id: Ref<string>) {
  const queryKey = computed(() => queryKeys.REGISTRATION.COMPANY(id.value))

  const queryFn = async () => {
    const { data } = await customerRegisterService.getCompanyRegistrationForm(id.value)
    return data
  }

  const query = useQuery({
    queryFn,
    queryKey,
    staleTime: 1000 * 60 * 1, // 1 minutes
    refetchInterval: 1000 * 60 * 1, // 1 minutes
  })

  watch([id], () => {
    query.refetch()
  })

  return query
}
